<template>
  <div class="m-login-form">
    <a href="/" class="m-login-form__logo-link">
      <img src="@/assets/logo_zone.svg" width="187" height="69" class="m-login-form__logo" />
    </a>
    <p class="m-login-form__suptitle">{{ $t('welcomeportal') }}</p>

    <div v-if="!invalidToken">
      <h2 class="m-login-form__title">{{ $t('createnewpassword') }}</h2>
      <p class="m-login-form__text">{{ $t('createnewpasswordtext') }}</p>
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <password-gcr label="yourpassword" invalidText="passwordrequired" v-model="password" :submitted="submitted" />
        </div>
        <button-gcr btnClass="m-login-form__btn btn-primary btn-block btn-arrow" label="createpassword" :loading="creating" />
      </form>
    </div>
    <div v-if="invalidToken">
      <p class="m-login-form__text">{{ $t('createpasswordInvalidToken') }}</p>
    </div>
    <p class="m-login-form__subnotice">{{ $t('createpasswordAgreement') }}</p>
  </div>
</template>

<script>
import { userService } from '@/_services'
import { Consts, Validations } from '@/_helpers'
import InputGcr from '@/components/atoms/Input'
import PasswordGcr from '@/components/atoms/Password'
import ButtonGcr from '@/components/atoms/Button'

export default {
  components: { InputGcr, PasswordGcr, ButtonGcr },
  data() {
    return {
      password: '',
      submitted: false,
      creating: false,
      invalidToken: false
    }
  },
  mounted() {
    userService.createpasswordchecktoken(this.$route.query.u, this.$route.query.token).then(data => {
      if (data.status == Consts.ERROR_CREATEPASSWORD_ALREADYCREATED) {
        this.$router.push('/')
      } else if (data.status == Consts.ERROR_CREATEPASSWORD_TOKENINVALID) {
        this.invalidToken = true
        userService.createpasswordresend(this.$route.query.u)
      }
    })
  },
  methods: {
    handleSubmit(evt) {
      this.submitted = true

      if (this.password) {
        if (!Validations.validePassword(this.password)) {
          this.$store.dispatch(Consts.ACTION_ALERT_SHOW, { type: Consts.ALERT_TYPE_ERROR, text: 'passwordInvalid' })
        } else {
          this.creating = true
          userService.createpassword(this.$route.query.u, this.$route.query.token, this.password).then(
            () => {
              this.$store.dispatch(Consts.ACTION_ALERT_SHOW, { type: Consts.ALERT_TYPE_INFO, text: 'createPasswordSucced' })
              this.$router.push('/')
            },
            error => {
              this.creating = false
              this.$store.dispatch(Consts.ACTION_ALERT_SHOW, { type: Consts.ALERT_TYPE_ERROR, text: 'unexpectedError' })
            }
          )
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../utilities/config';
.m-login-form {
  &__forgotpass {
    font-size: 14px;
  }
  &__suptitle {
    color: gray('gray-400');
    font-weight: 500; //font-weight: 400;;
    margin: 0;
  }
  &__title {
    margin-top: 0;
    margin-bottom: 16px;
  }
  &__text {
    font-size: 14px;
  }
  &__logo-link {
    display: block;
    margin-bottom: 24px;
  }
  &__btn {
    margin-top: 24px;
  }
  &__subnotice {
    color: gray('gray-400');
    font-size: 11px;
    font-style: italic;
    margin-top: 20px;
  }
}

@media screen and (min-width: breakpoint-min('sm')) {
  .m-login-form__logo-link {
    display: none;
  }
}
</style>
